body {
    overflow-x: hidden;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

/* .as-react-table .col-md-12{
    overflow-x: auto;
} */

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -10rem;
    }
}

#as-react-datatable-container select {
    width: 75px !important;
}

.react-autosuggest__suggestions-container{
    position: absolute;
    z-index: 2;
    background: #fff;
    padding: 10px 20px 0px 0px;
    border: 1px solid #e2e2e2;
}

.react-autosuggest__suggestion{
    list-style: none;
    margin: 6px -10px;
    border-bottom: 1px solid #e2e2e2;
    cursor: pointer;
}

.autocomplete_box input{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.autocomplete_box>div>div{
    z-index: 99;
    padding: 2px 10px;
}

.autocomplete_box>div{
    width: 100%;
}

.loading-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    background: #e2e2e2;
    z-index: 9;
    opacity: 0.4;
}

.loading-wrapper>span {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
}

.list-group-item{
    padding: 0.5rem !important;
}

.table th, .table td{
    padding: 0.4rem !important;
}
.form-group.col-md-12.alphabet span{
    padding: 2px;
    color: #007bff;
    font-weight: bold;
}
.as-react-table .asrt-page-length{
    display: initial;
}
.as-react-table .col-md-12{
    padding-top: 26px;
}
.advanceSearch{
    width: 500px !important ;
}
.advanceSearch input{
    width: 300px;
    float: left;
    margin-left: 20px;
}
.advanceSearch button{
    width: 75px;
    float: left;
    margin-left: 5px;
}
.advanceSearch button1{
    width: 125px;
    float: left;
    margin-left: 5px;
}
body .navbar{
    padding: 0.25rem 1rem;
}

.float-left{
    float: left;
}
.float-right{
    float: right;
}
.float-right-padding{
    padding-left: 333px;
    padding-top: 3px;
}
.card.shadow-lg .map{position: relative !important;}

a.list-group-item.list-group-item-action, .navbar-brand{
    background-color: #1D2127;
    color: #abb4be;
    border: 1px solid rgba(225, 225, 225, 0.125);
}
a.list-group-item.list-group-item-action:hover{
    color: #fff;
}
div#sticky-sidebar{
    z-index: 999;
}
body .bg-dark {
    background-color: #1D2127 !important;
}

footer .container.p-4.pb-0{
    padding: 0 !important;
    margin-top: 10px;;
}

i.fa.fa-calendar{
    position: absolute;
    right: 10px;
    font-size: 18px;
    bottom: 10px;
}

.list-group.list-group-flush .navbar-brand{
    padding: 8px 3px;
    background: #fff;
    margin-right: 0;
}
.list-group.list-group-flush .navbar-brand img{
    width: 200px;
}
#sidebar-wrapper .svg-inline--fa{
    margin-right: 10px;
}

.group-list button.btn.btn-outline-primary.float-right.mt-3.mr-2.a, .group-list .table_tools{
    margin-right: 210px !important;
}

.reports button.btn.btn-outline-primary.float-right.mt-3.mr-2, .reports .table_tools{
    margin-right: 250px !important;
}
.form-group.col-md-3.text-center.py-4.px-4.font-weight-bold.text-white{
    font-size: 16px;
}

.form-group.col-md-12.alphabet{
    text-align: center;
    font-size: 16px;
}

a.list-group-item.list-group-item-action, .navbar-brand{
    background-color: initial;
    border: initial;
}
.sidebar-btn-wrapper.logout{
    cursor: pointer;
}
body .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
    padding: 4px 15px 4px 10px;
}
body .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item{
    padding: 4px 15px 4px 15px;
}

.pro-sidebar.collapsed .pro-sidebar-header svg{
    margin: 10px 18px !important;
}
.pro-sidebar .pro-sidebar-header svg{
    font-size: 25px;
    cursor: pointer;
    margin-bottom: -5px;
    color: #1D2127;
}
.row .pro-sidebar{
    position: fixed !important;
    min-width: 250px;
    width: 250px;
}
.pro-sidebar .pro-sidebar-header img{
    width: 150px;
    margin: 10px 20px;
}

.pro-sidebar-header{
    background-color: #fff;
}
body .pro-sidebar.collapsed{
    width: 60px;
    min-width: 60px;
}
a.list-group-item.list-group-item-action {
    padding-left: 0 !important;
}
.collapsed .pro-sidebar-footer span.pro-item-content{
    background: #2b2b2b;
}

.collapsed .pro-sidebar-footer a.list-group-item.list-group-item-action{
    padding: 3px !important;
}
.collapsed .pro-sidebar-footer a.list-group-item.list-group-item-action .logout-text{
    display: none;
}
#add-report-modal i.fa.fa-calendar{
    right: 20px;
}

.age-card .left{
    float: left;
}
.age-card .right{
    text-align: right;
}
.card-body .left{
    float: left;
    display: contents;
}
.card-body .right{
    text-align: right;}
.card-body .col-md-6.mr-4.text-center.py-2.px-4{
    float: left;
    background: #DC3B2D;

}

th.non-sortable.asc.text-left{
   background-image: none !important;
}

.alererx svg.svg-inline--fa{
    width: 22px;
}
.alererx .list-group-items{
    list-style: none;
}

#pills-tab-login .nav-item{
    width: 50%;
    text-align: center;
}

.static-image{
    opacity: 0.3;
    width: 100%;
}

.static-card{
    position: relative;
    text-align: center;
}

.static-card .card{
    position: absolute;
    z-index: 9;
    margin: 25% 18% 0% 25%;
}
.static-card .card btn.btn-primary {
    margin-top: 10px;
}

.help-text{
    font-size: 15px;
    color: #007bff;
}
.nam{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* filter: blur(8px);
    -webkit-filter: blur(8px); */
}
.text-align-center{
    text-align: center;
}
button.btn.bg-primary.text-white.float-right.mt-3.mr-2.dont-see {
    background: #ff7600 !important;
}
p.help-text.font-weight-bold{
    color: #393838 !important;
    font-weight: 500 !important;
    font-size: 1.1rem;
    margin: 18px 30px 18px 0;
}
button.btn.btn-secordry{
    border: 2px solid #c2c0c0;
    margin: 0 5px;;
}

h1.mt-2.text-primary{
    color: #393838 !important;;
}
body .btn-primary, body .btn-primary:hover, body .nav-pills .nav-link.active, body .nav-pills .show>.nav-link{
    background-color: #2A3E90;
    border-color: #2A3E90;
}
body a, body th.sortable{
    color: #2A3E90;
}
body .bg-primary {
    background-color: #2A3E90 !important;
}

#pills-tab li.nav-item{
    border: 2px solid #2A3E90;
}

.nam h2.text-left.text-primary.mt-3{
    color: #2A3E90 !important;
}

.change-pass h5{
    float: left;
}
.change-pass .form-check-input{
    position: relative;
    left: 30px;
}

.term-condition {
    overflow-y: scroll;
    height: 500px;
}
#term-condition .modal-footer{
    display: block;
}

#term-condition input.form-check.float-left{
    margin-top: 10px;
    margin-right: 10px;
}

#term-condition p.float-left{
    margin-top: 8px;
}

#login-redirect .card.col-md-4 {
    padding: 20px 40px;
}

#login-redirect .modal-content{
    top: 100px;;
}
.display-none{
    display: none;
}
.card.col-md-4.selected {
    border: 3px solid #2A3E90;
}
.custom-ui{
    padding: 30px 50px;
    background: #e2e2e2;
    border-radius: 5px;
}
.custom-ui .registerbtn{
    background-color: #25b125;
}

td.email.email-blurr {
    color: transparent;
    text-shadow: 0 0 5px rgb(0 0 0 / 70%);
}
img.sc-dkPtRN.bgJYxZ{
    margin-bottom: 5px;
}
.sc-gsDKAQ.bsIdxO{
    font-size: 120%;
    border: 1px solid #e2e2e2;
    padding-top: 16px;
}

.mme button.btn{
    color: #2A3E90;
    padding: 0px 5px;
}
.cal-price{
    color: red;
    margin-top: 7px;
}
.kJnpoy{
    padding: 15px;
    font-size: 140%;
}

.kJnpoy .hlTfmo{
    padding-top: 2px;
}
a.list-group-item.list-group-item-action.is-active {
    color: #a2e5ff;
}
.left{
    text-align: left !important;
}

.register label{
    margin-top: 15px;
    margin-bottom: 5px;
}
.login .col-md-4.mx-auto.mt-5.mb-5.card.shadow-lg{
    border-radius: 10px;
}
footer.text-center.text-lg-start.text-white.bg-primary{
    padding-left: 0 !important;
}
#main{
    margin-left: 250px;
}
.col-md-4.mx-auto.mt-3.mb-3.card.shadow-lg{
    border-radius: 8px;
}
.password-wrapper{
    position: relative;margin-bottom: 20px;
}

.password-wrapper .eye-icon{
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}